import gql from 'graphql-tag';

export default gql`
  mutation insertTeamItem($object: insertTeamItemInput!) {
    insertTeamItem(object: $object) {
      team_id
      folder_id
      project_id
    }
  }
`;
