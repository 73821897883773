import gql from 'graphql-tag';

export default gql`
mutation addTeamMember($obj: AddTeamMemberInput!) {
  addTeamMember(object: $obj) {
    role_id
    team_id
    user_id
  }
}
`;
