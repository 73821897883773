import gql from 'graphql-tag';

export default gql`
mutation deleteTeamItem($obj: DeleteTeamItemInput!) {
  deleteTeamItem(object: $obj) {
    team_id
    folder_id
    project_id
  }
}
`;
