<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex align-items-center">
        <h3 class="mb-0">{{ team.teamName }}</h3>
        <b-dropdown
          v-if="writeAccess"
          class="context-menu"
          toggle-class="dot-button"
          no-caret
          no-flip
          right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1"></b-icon>
          </template>
          <b-dropdown-item @click="openEditTeamModal = true;">
            {{ $t('dropdowns.edit') }}
          </b-dropdown-item>
          <b-dropdown-item @click="openDeleteTeamModal = true;">
            {{ $t('dropdowns.delete') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="11">
        <hr>
      </b-col>
      <modal-edit-team
        v-if="openEditTeamModal"
        class="d-inline-block"
        :team="team"
        @editTeam="editTeam"
        @cancel="openEditTeamModal = false"
      ></modal-edit-team>
      <modal-delete-team
        v-if="openDeleteTeamModal"
        class="d-inline-block"
        :team="team"
        @deleteTeam="deleteTeam"
        @cancel="openDeleteTeamModal = false"
      ></modal-delete-team>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11">
        <p class="mb-0">{{ team.teamDescription }}</p>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="3" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">
          {{ $t('modals.teamDescription.users') }}
        </h3>
      </b-col>
      <b-col cols="12" sm="8" class="align-content-end">
        <div class="float-right d-flex">
          <b-input-group class="search-group d-flex align-items-center">
            <b-icon
              icon="search"
              aria-hidden="true"
              scale="1"
            ></b-icon>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              :placeholder="$t('webmag.search')"
            ></b-form-input>
          </b-input-group>
          <b-button
            v-if="writeAccess"
            @click="showAddUserModal = true"
            class="green-button-transparent border-0 ml-3"
          >
            <b-icon
              icon="plus-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
            ></b-icon>
            {{ $t('buttons.addMember') }}
          </b-button>
          <modal-add-new-user
            v-if="writeAccess && showAddUserModal"
            :is-sys-admin="isSysAdmin"
            :inTeam="true"
            @addUser="addUser"
            @cancel="showAddUserModal = false"
          ></modal-add-new-user>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11">
        <b-table
          v-if="teamUsers"
          @sort-changed="sortChanged"
          @context-changed="sortChanged"
          id="userTable"
          ref="userTable"
          :fields="userFields"
          :items="teamUsers"
          :filter="filter"
          sort-icon-left
          show-empty
          borderless
          dark
          class="table-view users-table"
        >
          <template #empty="scope">
            <div class="text-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noUsers') }}
            </div>
          </template>
          <template #emptyfiltered="scope">
            <div class="table-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noFilteredUsers') }}
            </div>
          </template>
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width }"
            >
          </template>
          <template #cell(image)="data" class="test">
            <b-avatar
              v-if="data.item.image !== null"
              size="32px"
              :src="data.item.image"
            ></b-avatar>
            <b-avatar v-else size="32px"></b-avatar>
          </template>
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(email)="data">
            {{ data.item.email }}
          </template>
          <template #cell(role)="data">
            {{ data.item.role.name }}
          </template>
          <template #cell(status)="data">
            {{ data.item.status }}
          </template>
          <template #cell(dot)="data">
            <user-management-dropdown
              v-if="writeAccess"
              :key="number"
              :team="team"
              :teamIndex="teamId"
              :user-object="data.item"
              @editUser="editUser"
              @deleteUser="deleteUser"
              @sendInvitationReminder="sendInvitationReminder"
            ></user-management-dropdown>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="3" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">
          {{ $t('modals.teamDescription.items') }}
        </h3>
      </b-col>
      <b-col cols="12" sm="8" class="align-content-end">
        <div class="float-right">
          <modal-add-new-team-item
            v-if="writeAccess && userId"
            class="d-inline-block"
            :user-id="userId"
            :items-from-d-b="items"
            :items="items"
            @addItems="addItems"
          ></modal-add-new-team-item>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" offset-sm="1" sm="11">
        <b-table
          :fields="itemFields"
          :items="items"
          show-empty
          borderless
          dark
          class="table-view items-table"
        >
          <template #empty="scope">
            <div class="text-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noItems') }}
            </div>
          </template>
          <template #emptyfiltered="scope">
            <div class="table-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noFilteredItems') }}
            </div>
          </template>
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width }"
            >
          </template>
          <template #cell(image)="data" class="test">
            <div
              v-if="data.item.type === 'folder'"
              class="cell-folder cell-background-image d-flex
            justify-content-center align-items-center"
            >
              <b-icon
                icon="folder"
                aria-hidden="true"
                scale="1"
              ></b-icon>
            </div>
            <div
              v-if="data.item.type === 'project'"
              class="cell-background-image d-flex justify-content-center align-items-center"
            >
              <b-icon
                icon="files"
                aria-hidden="true"
                scale="1"
              ></b-icon>
            </div>
          </template>
          <template #cell(group)="data">
            <span :id="`tooltip-group-${data.item.id}`">
            {{ data.item.group_name | sliceStingPos(20) }}
            </span>
            <b-tooltip
              v-if="data.item.group_name.length > 20"
              :target="`tooltip-group-${data.item.id}`"
            >
              {{ data.item.group_name }}
            </b-tooltip>
          </template>
          <template #cell(path)="data">
            <span :id="`tooltip-path-${data.item.id}`">
            {{ data.item.path | sliceStingPos(25) }}
            </span>
            <b-tooltip
              v-if="data.item.path.length > 25"
              :target="`tooltip-path-${data.item.id}`"
            >
              {{ data.item.path }}
            </b-tooltip>
          </template>
          <template #cell(name)="data">
            <span :id="`tooltip-name-${data.item.id}`">
            {{ data.item.name | sliceStingPos(23) }}
            </span>
            <b-tooltip
              v-if="data.item.name.length > 23"
              :target="`tooltip-name-${data.item.id}`"
            >
              {{ data.item.name }}
            </b-tooltip>
          </template>
          <template #cell(dot)="data">
            <b-dropdown
              v-if="writeAccess"
              class="context-menu"
              toggle-class="dot-button"
              no-caret
              dropup
              no-flip
              right
            >
              <template #button-content>
                <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
              </template>
              <b-dropdown-item
                v-b-modal="`remove-${data.item.type}-${data.item.id}-${teamId}`"
              >
                {{ $t('modals.deleteTeamItem.dropdown') }}
              </b-dropdown-item>
            </b-dropdown>
            <modal-remove-item
              class="d-inline-block"
              :item="data.item"
              :team-index="teamId"
              @remove-item="removeItem"
            ></modal-remove-item>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="11" v-if="!writeAccess">
        <div class="info-box px-4 py-2 text-center">
          {{ $t('teamManagement.notAllowedToEdit') }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GetTeamDetails from '@/graphQlQueries/queries/getTeamDetails';
import UpdateTeam from '@/graphQlQueries/mutations/updateTeam';
import DeleteTeam from '@/graphQlQueries/mutations/deleteTeam';
import GetUserByEmail from '@/graphQlQueries/queries/getUserByEmail';
import AddTeamMember from '@/graphQlQueries/mutations/addTeamMember';
import SendInviteEmail from '@/graphQlQueries/mutations/sendInviteEmail';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import InsertTeamItem from '@/graphQlQueries/mutations/insertTeamItem';
import DeleteTeamItem from '@/graphQlQueries/mutations/deleteTeamItem';

export default {
  name: 'PageSettingsTeamPageDescription',
  components: {
    ModalEditTeam: () => import('@/components/modals/team/ModalEditTeam.vue'),
    ModalDeleteTeam: () => import('@/components/modals/team/ModalDeleteTeam.vue'),
    ModalAddNewUser: () => import('@/components/modals/ModalAddNewUser.vue'),
    UserManagementDropdown: () => import('@/components/settings/teams/UserManagementDropdown.vue'),
    ModalAddNewTeamItem: () => import('@/components/modals/team/ModalAddNewTeamItem.vue'),
    ModalRemoveItem: () => import('@/components/modals/ModalRemoveItem.vue'),
  },
  props: [
    'team',
    'teamId',
    'user',
  ],
  data() {
    return {
      showAddUserModal: false,
      userFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
        {
          key: 'name',
          label: this.$t('teamManagement.table.name'),
          sortable: true,
          width: '150',
          tdClass: 'name_column',
        },
        {
          key: 'email',
          label: this.$t('teamManagement.table.email'),
          sortable: true,
          width: 'auto',
          tdClass: 'email_column',
        },
        {
          key: 'role',
          label: this.$t('teamManagement.table.role'),
          sortable: true,
          width: '90px',
          tdClass: 'role_column',
        },
        {
          key: 'status',
          label: this.$t('teamManagement.table.status'),
          sortable: true,
          width: '75px',
          tdClass: 'status_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
      ],
      itemFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
        {
          key: 'name',
          label: this.$t('teamManagement.table.name'),
          sortable: true,
          width: '200px',
          tdClass: 'name_column',
        },
        {
          key: 'path',
          label: this.$t('teamManagement.table.path'),
          sortable: true,
          width: 'auto',
          tdClass: 'path_column',
        },
        {
          key: 'group',
          label: this.$t('teamManagement.table.group'),
          sortable: true,
          width: '150px',
          tdClass: 'group_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
      ],
      teamUsers: [],
      items: [],
      accessLevel: null,
      filter: null,
      number: Math.floor(Math.random() * 1000),
      teamsLoaded: false,
      itemsLoaded: false,
      newItemAddedKey: 0,
      openEditTeamModal: false,
      openDeleteTeamModal: false,
    };
  },
  filters: {
    sliceStingPos(value, length) {
      return (value.length > length) ? `${value.slice(0, length)}...` : value;
    },
  },
  apollo: {
    teamDetailData: {
      variables() {
        return {
          teamId: this.teamId,
        };
      },
      query: GetTeamDetails,
      update(data) {
        this.accessLevel = (data.getTeamDetails?.access) ? data.getTeamDetails.access : null;
        if (data.getTeamDetails.users?.length) {
          this.teamUsers = this.buildTheUsers(data.getTeamDetails.users);
        }
        // build the items
        const itemObjects = [];
        if (data.getTeamDetails.items?.length) {
          let itemId = 1;
          data.getTeamDetails.items.forEach((item) => {
            const element = {
              id: itemId,
              label: item.name,
              name: item.name === 'root_folder' ? item.group_name : item.name,
              description: 'to add...',
              type: item.type,
              folder_id: item.folder_id,
              project_id: item.project_id,
              group_id: item.group_id,
              group_name: item.group_name,
              path: item.slug_path,
            };
            itemId++;
            itemObjects.push(element);
          });
        }
        // sort the items after group_name and then after path
        itemObjects.sort((a, b) => {
          const textA = a.group_name.toUpperCase();
          const textB = b.group_name.toUpperCase();
          const textC = a.path.toUpperCase();
          const textD = b.path.toUpperCase();
          // eslint-disable-next-line no-nested-ternary,max-len
          return (textA < textB) ? -1 : (textA > textB) ? 1 : (textC < textD) ? -1 : (textC > textD) ? 1 : 0;
        });
        this.itemsLoaded = true;
        this.items = itemObjects;
      },
      skip() {
        return !this.teamId;
      },
      fetchPolicy: 'network-only',
    },
  },
  watch: {
    teamsLoaded(newValue) {
      if (newValue && this.itemsLoaded) {
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
        this.teamsLoaded = false;
        this.itemsLoaded = false;
      }
    },
    itemsLoaded(newValue) {
      if (newValue && this.teamsLoaded) {
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
        this.teamsLoaded = false;
        this.itemsLoaded = false;
      }
    },
  },
  computed: {
    writeAccess() {
      if (this.user?.isSysAdmin) {
        return true;
      }
      switch (this.accessLevel) {
        case 'OWNER':
        case 'WRITE':
          return true;
        case 'READ':
          return false;
        default:
          return false;
      }
    },
    isSysAdmin() {
      return !!this.user?.sys_admin;
    },
    userId() {
      return this.user?.userId;
    },
  },
  methods: {
    sortChanged() {
      this.number++;
    },
    getImageUrl(imageObject) {
      let cloudImageUrl = null;
      if (imageObject?.url) {
        cloudImageUrl = this.$makeCloudImage(
          imageObject.url,
          {
            width: 32,
            height: 32,
          },
          imageObject,
          null,
          true,
        );
      }
      return (imageObject?.url)
        ? cloudImageUrl
        : null;
    },
    buildTheUsers(usersFromDb) {
      const users = [];
      usersFromDb.forEach((userItem) => {
        const user = {
          userId: userItem.user_id,
          name: userItem.name,
          email: userItem.email,
          role: {
            role_id: userItem.role_id,
            name: userItem.role_name,
            handle: userItem.role_handle,
            description: userItem.role_description,
          },
          deactivated: userItem.deactivated,
          active: userItem.active,
          // eslint-disable-next-line no-nested-ternary
          status: userItem.deactivated ? this.$t('teamManagement.deactivated') : (userItem.email_confirmed ? this.$t('teamManagement.online') : this.$t('teamManagement.invited')),
        };
        user.image = (userItem.profile_pictures)
          ? this.getImageUrl(userItem.profile_pictures)
          : null;
        users.push(user);
      });
      // sort the users first after the role name asc and then after the email
      users.sort((a, b) => {
        const textA = a.role.name.toUpperCase();
        const textB = b.role.name.toUpperCase();
        const textC = a.email.toUpperCase();
        const textD = b.email.toUpperCase();
        // eslint-disable-next-line no-nested-ternary,max-len
        return (textA > textB) ? -1 : (textA < textB) ? 1 : (textC < textD) ? -1 : (textC > textD) ? 1 : 0;
      });
      return users;
    },
    async editTeam(teamName, teamDescription) {
      console.log('editing team...');
      try {
        await this.$apollo.mutate({
          mutation: UpdateTeam,
          variables: {
            obj: {
              teamId: this.teamId,
              name: teamName,
              description: teamDescription === '' ? null : teamDescription,
            },
          },
        });
        this.openEditTeamModal = false;
      } catch (ex) {
        console.error(ex);
      }
      this.team.teamName = teamName;
      this.team.teamDescription = teamDescription;
      this.number++;
    },
    async deleteTeam() {
      console.log('deleting team...');
      try {
        await this.$apollo.mutate({
          mutation: DeleteTeam,
          variables: {
            obj: {
              teamId: this.team.teamId,
              name: this.team.teamName,
            },
          },
        });
      } catch (ex) {
        console.error(ex);
      }
      this.openDeleteTeamModal = false;
      this.$emit('deleteTeam');
    },
    async addUser(name, email, role) {
      console.log('adding new user...', email);
      try {
        // check if user already exists
        const newAddedUser = await this.$apollo.query({
          query: GetUserByEmail,
          variables: {
            email,
          },
        });
        const { data: { addTeamMember: { user_id: userId } } } = await this.$apollo.mutate({
          mutation: AddTeamMember,
          variables: {
            obj: {
              roleId: role.role_id,
              teamId: this.team.teamId,
              email,
              fullName: name,
            },
          },
        });
        const currentUserNameOrEmail = (this.user.name) ? this.user.name : this.user.email;
        if (newAddedUser.data.users.length === 0) {
          // Send email
          await this.$apollo.mutate({
            mutation: SendInviteEmail,
            variables: {
              subject: this.$t('teamManagement.email.invite.subject'),
              to: email,
              html: this.$t('teamManagement.email.invite.text', {
                team: this.team.teamName,
                email,
                name: (name) ? encodeURIComponent(name) : '',
                user_email: currentUserNameOrEmail,
              }),
            },
          });
          this.$bvToast.toast(this.$t('teamManagement.email.inviteToast.title', { email }), {
            title: this.$t('teamManagement.email.inviteToast.text'),
            solid: true,
          });
        } else {
          // Send email
          await this.$apollo.mutate({
            mutation: SendInviteEmail,
            variables: {
              subject: this.$t('teamManagement.email.notify.subject'),
              to: email,
              html: this.$t('teamManagement.email.notify.text', {
                team: this.team.teamName,
                user_email: currentUserNameOrEmail,
              }),
            },
          });
          this.$bvToast.toast(this.$t('teamManagement.email.notifyToast.title', { email }), {
            title: this.$t('teamManagement.email.notifyToast.text'),
            solid: true,
          });
        }
        this.teamUsers.push({
          name,
          email,
          role,
          userId,
          status: this.$t('teamManagement.invited'),
        });
      } catch (ex) {
        console.error(ex);
      }
      this.showAddUserModal = false;
    },
    async editUser() {
      console.log('editing user...', this.$apollo.queries);
      await this.$apollo.queries.teamDetailData.refresh();
    },
    async deleteUser(userId) {
      this.teamUsers = this.teamUsers.filter((user) => user.userId !== userId);
    },
    async sendInvitationReminder(email, name) {
      try {
        const currentUserNameOrEmail = (this.user.name) ? this.user.name : this.user.email;
        await this.$apollo.mutate({
          mutation: SendInviteEmail,
          variables: {
            subject: this.$t('teamManagement.email.reminder.subject'),
            to: email,
            html: this.$t('teamManagement.email.reminder.text', {
              email,
              team: this.team.teamName,
              name: (name) ? encodeURIComponent(name) : '',
              user_email: currentUserNameOrEmail,
            }),
          },
        });
        this.$bvToast.toast(this.$t('teamManagement.email.reminderToast.title', { email }), {
          title: this.$t('teamManagement.email.reminderToast.text'),
          solid: true,
        });
      } catch (ex) {
        console.error(ex);
      }
    },
    addItems(itemsToInsert, itemsToDelete) {
      console.log('adding new item...');
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      const addDeleteItemsPromises = [];
      if (itemsToInsert.length) {
        for (let i = 0; i < itemsToInsert.length; i++) {
          const { itemId } = itemsToInsert[i];
          const { type } = itemsToInsert[i];
          let insertObject = {
            team_id: this.teamId,
            folder_id: null,
            project_id: null,
          };

          switch (type) {
            case 'group':
              insertObject.folder_id = itemsToInsert[i].rootFolderId;
              break;
            case 'folder':
              insertObject.folder_id = itemId;
              break;
            case 'project':
              insertObject.project_id = itemId;
              break;
            default:
              insertObject = null;
              break;
          }
          if (insertObject !== null) {
            addDeleteItemsPromises.push(this.$apollo.mutate({
              mutation: InsertTeamItem,
              variables: {
                object: insertObject,
              },
            }));
          }
        }
      }
      if (itemsToDelete.length) {
        for (let i = 0; i < itemsToDelete.length; i++) {
          const item = itemsToDelete[i];
          const obj = {
            team_id: this.teamId,
            folder_id: null,
            project_id: null,
          };
          switch (item.type) {
            case 'group':
              obj.folder_id = item.rootFolderId;
              break;
            case 'folder':
              obj.folder_id = item.itemId;
              break;
            case 'project':
              obj.project_id = item.itemId;
              break;
            default:
              break;
          }

          addDeleteItemsPromises.push(this.$apollo.mutate({
            mutation: DeleteTeamItem,
            variables: { obj },
          }));
        }
      }
      if (addDeleteItemsPromises.length > 0) {
        Promise.allSettled(addDeleteItemsPromises).then(() => {
          this.$apollo.queries.teamDetailData.refetch();
          this.$apollo.mutate({
            mutation: SetLoadingIndicator,
            variables: {
              isIndicatorLoading: false,
            },
          });
        }).catch((e) => {
          console.log('error', e);
          this.$apollo.mutate({
            mutation: SetLoadingIndicator,
            variables: {
              isIndicatorLoading: false,
            },
          });
        });
      }
    },
    async removeItem(item) {
      console.log('removing item...', item);
      try {
        await this.$apollo.mutate({
          mutation: DeleteTeamItem,
          variables: {
            obj: {
              team_id: this.teamId,
              folder_id: item.folder_id,
              project_id: item.project_id,
            },
          },
        });
        await this.$apollo.queries.teamDetailData.refetch();
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.h3 {
  display: inline-block;
}

.cell-background-image {
  width: 100%;
  height: 100%;
  min-height: 36px;
  min-width: 50px;
  background-color: #777C7F;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.table-dark {
  background-color: transparent;
}

/deep/ .table-view .btn.dot-button {
  padding: 0;
  line-height: 1;

  text-align: center;
  vertical-align: text-top;
}

/deep/ table.table-view {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;
  color: $webmag-light-grey;

  thead th {
    text-transform: uppercase;
    font-size: 13px;
    padding: 7px 8px;
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &:first-child {
        padding: 0;
        vertical-align: middle;
        text-align: center;
      }

      &:last-child {
        xdisplay: none;
        padding: 0;

        button {
          width: 40px;
          min-height: 36px;
        }
      }
    }
  }
}

.search-group {
  width: 150px;
  background-color: #5b6064;
  padding-left: 11px;
}

#content #filter-input, #content #filter-input:focus {
  color: #b9b9b9;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  height: calc(1.5em + 0.75rem)
}

#filter-input::-webkit-search-cancel-button {
  appearance: none;
  height: 23px;
  width: 20px;
  background-size: 21px;
  background: url('../../../assets/svg/icon-search-cancel.svg') no-repeat center center;
  cursor: pointer;
}

button.btn.active svg {
  color: #5cb85c;
}

/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}

.alert-message {
  border: 1px solid #A05B5B;
}
</style>
