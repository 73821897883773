import gql from 'graphql-tag';

export default gql`
query getTeamDetails($teamId: Int!) {
  getTeamDetails(object: { teamId: $teamId }) {
    name
    description
    access
    items
    users
  }
}
`;
